.tg-light{

    .button-white {
        background: var(--tg-theme-button-color);
        color: #fff;
    }

    .select-currency {
        background: var(--tg-theme-bg-color);
        border-radius: 8px;
    }

    .question-icon {
        color: #3a3a3b;
    }

}