.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(33, 33, 33, 0.4);
    z-index: var(--tgui--z-index--overlay);
}

.drawer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 90vh;
    background-color: var(--tg-theme-secondary-bg-color);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    transition: transform 0.3s ease-in-out;
    transform: translate3d(0, 100%, 0);
    z-index: var(--tgui--z-index--overlay);
    overflow: hidden;
    will-change: transform;
    padding-bottom: var(--tgui--safe_area_inset_bottom);
}


.drawer-header{
    padding: 16px;
}

.drawer-content {
    overflow-y: auto;
    max-height: 80vh; /* Максимальная высота модалки */
    touch-action: pan-y; /* Позволяет прокручивать содержимое */
    scroll-behavior: smooth; /* Плавная прокрутка */

}

.drawer-header:before {
    background: var(--tg-theme-subtitle-text-color);
    border-radius: 4px;
    content: "";
    height: 4px;
    left: 50%;
    position: absolute;
    top: 8px;
    transform: translateX(-50%);
    width: 36px;
}