.tg-dark {

    .button-white {
        color: #000;
    }

    .question-icon {
        color: #484747;
    }

}